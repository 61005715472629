import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "home",
  id: "home"
}
const _hoisted_2 = {
  class: "main-content",
  style: {"background-color":"transparent"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_TestHome = _resolveComponent("TestHome")!
  const _component_FooterBar = _resolveComponent("FooterBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopBar),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view),
      _createVNode(_component_TestHome),
      _createVNode(_component_FooterBar)
    ])
  ]))
}