<template>
  <div class="button-group">
    <button class="btn-info" @click="scrollToComponent('expertise')">
      En savoir plus
    </button>
    <ContactForm></ContactForm>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "ButtonGroup",
  components: {
    ContactForm,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
  },
  setup() {
    const scrollToComponent = (componentId) => {
      const element = document.getElementById(componentId);
      const header = document.querySelector(".header");

      if (element) {
        const yOffset = header ? -header.offsetHeight : 0;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      } else {
        console.error(`Component with ID '${componentId}' not found.`);
      }
    };

    return {
      scrollToComponent,
    };
  },
};
</script>

<style lang="scss">
.button-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.modal {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  &:hover,
  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
}

.btn-info {
  display: flex;
  padding: 15px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  cursor: pointer;
  color: #fff;
  font-family: var(--font-primary);
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid var(--D_01, #6c60ff);
  background: var(--D2, linear-gradient(155deg, #101028 33.6%, #211b63 93.25%));
}

@media (max-width: 1124px) {
  .button-group {
    flex-direction: column;
    gap: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    width: 100%;
  }

  .btn-info {
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: var(--font-primary);
    font-size: 0.8rem;
    margin-left: 0.1rem;
  }
}
</style>
