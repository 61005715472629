import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7f83ed7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "part-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SaleService = _resolveComponent("SaleService")!
  const _component_DiscoverUs = _resolveComponent("DiscoverUs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SaleService),
    _createVNode(_component_DiscoverUs)
  ]))
}