<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  font-family: "Karla", Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  background-color: #05051f;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: auto;
  overflow-x: hidden;
}

nav {
  padding: 5px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #05051f;
    }

    &.router-link-active {
      color: #05051f;
    }
  }
  overflow-x: hidden;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rethink+Sans&family=Roboto:wght@100&display=swap");

:root {
  --font-primary: "Roboto", sans-serif;
  --font-secondary: "Rethink Sans", sans-serif;
}

body,
html {
  margin: 0;
  background-color: #05051f;
  color: #05051f;
  font-family: var(--font-primary);
  text-align: center;
  overflow-x: hidden;
}

.main-content {
  flex: 1;
  padding: 1.25rem;
  //background-color: #05051f;
  color: #05051f;
  text-align: center;
  margin-top: 7.5rem;
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
