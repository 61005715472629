<template>
  <div class="testi-box" :class="{ active: isActive }">
    <div class="testi-content">
      <div class="content-box">
        <div class="testi-logo">
          <img :src="testimonial.logo" alt="Logo" />
        </div>
        <div class="testi-text">
          {{ testimonial.text }}
        </div>
        <div class="testi-person">
          <div class="person-photo">
            <img
              :src="testimonial.photo"
              alt="Photo"
              class="person-photo-img"
            />
          </div>
          <div class="person-info">
            <div class="person-name">
              {{ testimonial.name }}
            </div>
            <div class="person-title">
              {{ testimonial.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "TestimonialContent",
  props: {
    isActive: {
      type: Boolean,
      required: true,
    },
    testimonial: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.testi-box {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  opacity: 0.5;
  margin-top: -3rem;
  transition: opacity 0.5s ease-in-out;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;

  position: absolute;
  transition: transform 0.5s ease-in-out;
  width: 33.333%;
  left: 50%;
  transform: translateX(-50%);

  &.left {
    transform: translateX(-165%);
  }

  &.right {
    transform: translateX(65%);
  }

  &.hidden {
    transform: translateX(200%);
    visibility: hidden;
  }

  &.active {
    opacity: 1;
  }
}

.testi-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.2rem;
}

.testi-logo img {
  width: auto;
  height: 4.5rem;
}

.testi-text {
  color: white;
  text-align: center;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 1.375rem;
  font-weight: 700;
}

.testi-person {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.person-photo img {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
}

.person-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.person-name {
  color: white;
  text-align: center;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 1.1rem;
  font-weight: 550;
}

.person-title {
  color: white;
  text-align: center;
  font-family: "Arial", sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  opacity: 0.6;
}
</style>
