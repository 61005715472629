import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f5b8469c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "icon-container" }
const _hoisted_2 = { class: "group-content" }
const _hoisted_3 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(3, (n) => {
      return _createElementVNode("div", {
        class: "icon-box",
        key: n
      }, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, 'icon' + n, {}, undefined, true),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, 'title' + n, {}, undefined, true),
            _renderSlot(_ctx.$slots, 'subtitle' + n, {}, undefined, true)
          ])
        ])
      ])
    }), 64))
  ]))
}