<template>
  <div class="contact-form">
    <button :class="buttonClass" @click="toggleModal">{{ buttonText }}</button>
    <div v-if="showModal" class="modal" @click="closeModalOnClickOutside">
      <div class="modal-content" @click.stop>
        <span class="close" @click="toggleModal">&times;</span>
        <img src="assets/ContactUs.png" alt="Contact Us" />
        <div class="content-wrapper">
          <div class="text-side">
            <h1>Contactez-nous</h1>
            <p>
              Veuillez remplir le formulaire et nous vous contacterons sous peu.
            </p>
          </div>
          <div class="form-side">
            <form :action="formAction" method="POST">
              <div class="form-row">
                <div class="form-group">
                  <label for="name">Nom* :</label>
                  <input type="text" id="name" name="name" required />
                </div>
                <div class="form-group">
                  <label for="surname">Prénom* :</label>
                  <input type="text" id="surname" name="surname" required />
                </div>
              </div>
              <div class="form-group">
                <label for="phone">Numéro de téléphone :</label>
                <input type="tel" id="phone" name="phone" />
              </div>
              <div class="form-group">
                <label for="email">Email* :</label>
                <input type="email" id="email" name="email" required />
              </div>
              <div class="form-group">
                <label for="message">Message* :</label>
                <textarea id="message" name="message" required></textarea>
              </div>
              <button type="submit" class="submit-btn">Envoyer</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactForm",
  props: {
    buttonText: {
      type: String,
      default: "Contactez-nous",
    },
    buttonClass: {
      type: String,
      default: "btn",
    },
  },
  data() {
    return {
      showModal: false,
      formAction: process.env.VUE_APP_FORMSPREE_ACTION,
    };
  },
  created() {
    if (process.env.VUE_APP_FORMSPREE_ACTION) {
      console.log("Form action URL: " + process.env.VUE_APP_FORMSPREE_ACTION);
    } else {
      console.log("VUE_APP_FORMSPREE_ACTION is not defined");
    }
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
      this.$emit("toggle", this.showModal);
      this.$nextTick(() => {
        if (this.showModal) {
          this.$el.querySelector(".modal").style.zIndex = "9999";
        }
      });
    },
    closeModalOnClickOutside(event) {
      if (event.target.classList.contains("modal")) {
        this.showModal = false;
        this.$emit("toggle", this.showModal);
      }
    },
  },
};
</script>

<style scoped>
.modal {
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 100;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: row;
  max-width: 80%;
  max-height: 90%;
  margin: auto;
  background-color: #211b63;
  border-radius: 10px;
  gap: 10px;
  overflow: auto;
  overflow-x: hidden;
  z-index: 14;
}

.text-side,
.form-side {
  flex: 1;
}

.text-side {
  background-color: #211b63;
  text-align: left;
  color: white;
  font-family: var(--font-primary);
  .h2 {
    font-size: 2.5rem;
  }
  margin-left: 25px;
}

.form-side {
  background-color: #211b63;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  text-align: left;
  color: white;
}

.form-group label {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group textarea {
  font-size: 1rem;
  padding: 10px;
  border-radius: 5px;
}

.form-group textarea {
  font-size: 1rem;
  padding: 10px;
  border-radius: 5px;
  height: 150px;
  font-family: var(--font-primary);
}

.submit-btn {
  padding: 10px 60px;
  font-size: 1.1rem;
  margin-top: 20px;
  background-color: #862dff;
  color: white;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-family: var(--font-primary);
  width: 100%;
  box-sizing: border-box;
}

.close {
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 2rem;
  background: none;
  color: white;
  cursor: pointer;
}

.close:hover {
  color: lightgray;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #211b63;
  margin-left: 30px;
}

.modal-content img {
  width: 100%;
  max-height: 80%;
  border-radius: 10px;
  margin: 0;
}

.form-row {
  display: flex;
  gap: 30px;
}

@media (max-height: 700px) {
  .modal-content img {
    display: none;
  }

  .modal-content {
    flex-direction: column;
    max-width: 70%;
    align-items: center;
  }

  .form-row {
    width: 100%;
  }

  .content-wrapper {
    margin-left: 0;
  }

  .form-row .form-group {
    width: 100%;
    margin-left: 0;
  }
}

@media (max-width: 1400px) {
  .modal-content {
    flex-direction: column;
    max-width: 70%;
    align-items: center;
  }

  .form-row {
    width: 100%;
  }

  .content-wrapper {
    margin-left: 0;
  }

  .form-row .form-group {
    width: 100%;
    margin-left: 0;
  }

  .modal-content img {
    display: none;
  }
}

@media (max-width: 768px) {
  .modal-content {
    flex-direction: column;
    max-width: 70%;
  }

  .modal-content img {
    height: 9.375rem;
    width: 100%;
    object-fit: cover;
    object-position: center;
    display: none;
  }

  .text-side,
  .form-side {
    padding: 2px;
  }

  .text-side h1 {
    font-size: 1rem;
  }

  .text-side p {
    font-size: 0.8rem;
  }

  .form-group label,
  .form-group input,
  .form-group textarea,
  .submit-btn {
    font-size: 0.6rem;
  }

  .form-group textarea {
    height: 2.5rem;
  }

  .content-wrapper {
    margin-left: 0;
  }

  .form-row {
    flex-direction: column;
    gap: 1.5px;
  }
}

@media (max-width: 380px) {
  .modal-content {
    flex-direction: column;
    max-width: 85%;
    max-height: 77%;
    overflow: auto;
    top: 7%;
  }

  .form-row {
    flex-direction: row;
  }

  .form-row .form-group {
    max-width: 48%;
  }

  .form-row .form-group:first-child {
    margin-right: 10px;
  }
}

@media (max-width: 950px) and (min-width: 700px) and (max-height: 700px) and (min-height: 300px) {
  .modal-content {
    flex-direction: column;
    max-height: 16rem;
    align-items: center;
    top: 3.68rem;
  }
}

@media (max-width: 670px) and (min-width: 660px) and (max-height: 380px) and (min-height: 370px) {
  .modal-content {
    flex-direction: column;
    max-height: 16rem;
    align-items: center;
    top: 3.68rem;
  }
}

@media (max-width: 1300px) and (min-width: 1050px) and (max-height: 820px) and (min-height: 790px) {
  .modal-content {
    flex-direction: column;
    max-height: 30rem;
    align-items: center;
  }
}

@media (min-width: 2600px) {
  .modal-content {
    max-width: 70rem;
    max-height: 60rem;
  }

  .modal-content img {
    height: 50rem;
    width: 30rem;
  }
}
</style>
