<template>
  <div class="number-container">
    <div class="number-box">
      <div v-for="counter in counters" :key="counter.id" class="number-content">
        <div class="number">
          <h1>{{ counter.current }}{{ counter.suffix }}</h1>
          <p class="description">{{ counter.description }}</p>
        </div>
        <div v-if="counter.id !== counters.length" class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SomeNumber",
  data() {
    return {
      counters: [
        {
          id: 1,
          end: 5,
          current: 0,
          suffix: "k+",
          description: "Assets managés",
        },
        {
          id: 2,
          end: 50,
          current: 0,
          suffix: "+",
          description: "Clients réguliers",
        },
        {
          id: 3,
          end: 100,
          current: 0,
          suffix: "+",
          description: "Projets réalisés",
        },
      ],
      observer: null,
    };
  },
  methods: {
    countUp(ref, end, current) {
      if (current < end) {
        let increment = Math.ceil(end / 120);
        requestAnimationFrame(() => {
          this.counters.find((c) => c.id === ref).current += increment;
          this.countUp(
            ref,
            end,
            this.counters.find((c) => c.id === ref).current
          );
        });
      } else {
        this.counters.find((c) => c.id === ref).current = end;
      }
    },
    isVisible(entry) {
      if (entry.isIntersecting) {
        this.counters.forEach((counter) => {
          this.countUp(counter.id, counter.end, counter.current);
        });
        this.observer.disconnect();
      }
    },
    setupObserver() {
      this.observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => this.isVisible(entry));
        },
        { threshold: 0.5 }
      );
      this.observer.observe(this.$el);
    },
  },
  mounted() {
    this.setupObserver();
  },
};
</script>

<style scoped lang="scss">
.number-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  align-self: stretch;
}
.number-box {
  display: flex;
  width: 75rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: -0.625rem;
  border-radius: 0.625rem;
  border: 1px solid var(--BLANC, #fff);
  background: var(
    --D3,
    linear-gradient(
      224deg,
      rgba(98, 86, 245, 0.8) 42.9%,
      rgba(134, 45, 255, 0.8) 100%
    )
  );
}
.number-content {
  display: flex;
  padding: 4rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 4.125rem;
  align-self: stretch;
  border-radius: 0.625rem;
  margin-top: -1.625rem;
}
.number {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0rem;
  color: var(--BLANC, #fff);
  text-align: center;
  font-family: var(--font-primary);
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
.line {
  width: 0.0625rem;
  height: 6.1875rem;
  background: #fff;
  margin-top: -0.725rem;
}

.description {
  color: var(--BLANC, #fff);
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  font-family: var(--font-secondary);
  margin-top: 0.35rem;
  margin-bottom: -0.45rem;
}

@media (max-width: 1424px) {
  .number-box {
    width: 60rem;
  }
}

@media (max-width: 800px) {
  .number-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    align-self: stretch;
  }
  .number-box {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.625rem;
    border: 1px solid var(--BLANC, #fff);
    background: var(
      --D3,
      linear-gradient(
        224deg,
        rgba(98, 86, 245, 0.8) 42.9%,
        rgba(134, 45, 255, 0.8) 100%
      )
    );
  }
  .number-content {
    display: flex;
    padding: 2rem 1rem;
    justify-content: center;
    align-items: center;
    gap: 1.125rem;
    align-self: stretch;
    border-radius: 0.625rem;
    margin-top: -1.625rem;
  }
  .number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    color: var(--BLANC, #fff);
    text-align: center;
    font-family: var(--font-primary);
    font-size: 2.5rem;
    font-style: normal;
    line-height: 100%;
  }
  .line {
    width: 0.0625rem;
    height: 6.1875rem;
    background: #fff;
    display: none;
  }
}
</style>
