<template>
  <div class="group-content">
    <div class="features-containers">
      <div class="feature-content">
        <img src="assets/img_tmp/feature1.png" alt="feature1" />
        <div class="text-container">
          <h3 class="feature-title">Expertise spécialisée</h3>
          <p class="feature-text">
            CCS se distingue par son équipe hautement qualifiée et spécialisée.
            Nous investissons constamment dans la formation pour rester à la
            pointe des technologies.
          </p>
        </div>
      </div>
      <div class="feature-content">
        <img src="assets/img_tmp/feature2.png" alt="feature1" />
        <div class="text-container">
          <h3 class="feature-title">Approche personnalisée</h3>
          <p class="feature-text">
            Pour nous, chaque projet est unique. Notre approche centrée sur le
            client assure des solutions sur mesure et une collaboration étroite
            pour garantir la réussite.
          </p>
        </div>
      </div>
      <div class="feature-content">
        <img src="assets/img_tmp/feature3.png" alt="feature1" />
        <div class="text-container">
          <h3 class="feature-title">Engagement de qualité</h3>
          <p class="feature-text">
            La qualité est notre priorité. Nous nous engageons à fournir des
            solutions de haute qualité qui dépassent les attentes à chaque fois.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "PourquoiCcs",
};
</script>

<style scoped lang="scss">
.group-content {
  display: flex;
  width: 75rem;
  gap: 2rem;
}
.features-containers {
  display: flex;
  width: 75rem;
  justify-content: center;
  align-items: center;
  padding: 0 9%;
  gap: 2rem;
  flex-wrap: nowrap;
}
.feature-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.feature-title {
  color: var(--BLANC, #fff);
  font-family: var(--font-primary);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.feature-text {
  align-self: stretch;
  align-items: flex-start;
  flex-wrap: wrap;
  color: var(--BLANC, #fff);
  font-family: var(--font-primary);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
  text-align: left;
}

.feature-content img {
  width: 18.96319rem;
  height: 21.875rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  border: 1px solid var(--D_01, #6c60ff);
  align-self: stretch;
}

@media screen and (max-width: 1124px) {
  .group-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2rem;
  }
  .features-containers {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    gap: 2rem;
    flex-wrap: nowrap;
  }
  .feature-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    align-self: stretch;
    flex-wrap: wrap;
    text-align: left;
  }
  .feature-title {
    color: var(--BLANC, #fff);
    font-family: var(--font-primary);
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
  }
  .feature-text {
    align-self: stretch;
    align-items: center;
    flex-wrap: wrap;
    color: var(--BLANC, #fff);
    font-family: var(--font-primary);
    font-size: 0.8375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
    text-align: left;
  }

  .feature-content img {
    width: 13.96319rem;
    height: 16.875rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    border: 1px solid var(--D_01, #6c60ff);
    align-self: stretch;
  }
}

@media (max-width: 768px) {
  .group-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
  }
  .features-containers {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    gap: 2rem;
    flex-wrap: nowrap;
  }
  .feature-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    align-self: stretch;
    flex-wrap: wrap;
  }
  .feature-title {
    color: var(--BLANC, #fff);
    font-family: var(--font-primary);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .feature-text {
    align-self: stretch;
    align-items: center;
    flex-wrap: wrap;
    color: var(--BLANC, #fff);
    font-family: var(--font-primary);
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.6;
    text-align: left;
  }

  .feature-content img {
    width: 18.96319rem;
    height: 21.875rem;
    flex-shrink: 0;
    border-radius: 0.625rem;
    border: 1px solid var(--D_01, #6c60ff);
    align-self: stretch;
  }
}
</style>
