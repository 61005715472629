<template>
  <div class="icon-container">
    <div class="icon-box" v-for="n in 3" :key="n">
      <div class="group-content">
        <slot :name="'icon' + n"></slot>
        <div class="description">
          <slot :name="'title' + n"></slot>
          <slot :name="'subtitle' + n"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "IconExpertise",
};
</script>

<style scoped lang="scss">
.icon-container {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
}

.icon-box {
  display: flex;
  padding: 2rem;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 1.125rem;
  box-sizing: border-box;
}

.group-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

@media (max-width: 1400px) {
  .icon-box {
    width: 100%;
    flex: 0 0 0;
  }
}

@media (max-width: 768px) {
  .icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
  }

  .icon-box {
    display: flex;
    padding: 2rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex: 1 0 0;
    border-radius: 1.125rem;
  }

  .group-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  .title {
    color: var(--BLANC, #fff);
    font-family: var(--font-primary);
    text-align: center;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .subtitle {
    color: var(--BLANC, #fff);
    text-align: center;
    font-family: var(--font-secondary);
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 16.125rem;
    opacity: 0.6;
  }
}
</style>
