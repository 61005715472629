<template>
  <div class="header">
    <div class="inner-header">
      <div class="logo-container" @click="scrollToComponent('home')">
        <img class="logo" alt="CCS logo" src="assets/WHITE_FULL.svg" />
      </div>
      <button
        class="menu-btn"
        @click="toggleMenu"
        style="right: 5%; top: 2.188rem"
      >
        &#9776;
      </button>
      <div class="navigation" v-show="isMenuVisible">
        <button class="other-btn" @click="scrollToComponent('pourquoi-ccs')">
          Pourquoi CCS ?
        </button>
        <button class="other-btn" @click="scrollToComponent('expertise')">
          Notre offre
        </button>
        <!-- <button class="other-btn" @click="goToAboutPage">À propos</button> -->
        <ContactForm @toggle="handleContactFormToggle" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import ContactForm from "@/components/ContactForm.vue";

export default defineComponent({
  name: "TopBar",
  components: {
    ContactForm,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    handleContactFormToggle(isOpen: boolean) {
      this.$el.style.zIndex = isOpen ? "2" : "3";
    },
  },
  setup() {
    const isMenuVisible = ref(true);
    const router = useRouter();
    const route = useRoute();

    const goToAboutPage = () => {
      router.push({ name: "about" });
    };

    const goToHomePage = () => {
      router.push({ name: "home" });
    };

    onMounted(() => {
      if (window.innerWidth < 1124) {
        isMenuVisible.value = false;
      }
    });

    const toggleMenu = () => {
      isMenuVisible.value = !isMenuVisible.value;
    };

    const scrollToComponent = (componentId: string) => {
      if (route.name === "home") {
        const element = document.getElementById(componentId);
        const header = document.querySelector(".header");
        if (element && header) {
          const yOffset = -header.clientHeight;
          const y =
            element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      } else {
        router.push({ name: "home" }).then(() => {
          requestAnimationFrame(() => {
            const element = document.getElementById(componentId);
            const header = document.querySelector(".header");
            if (element && header) {
              const yOffset = -header.clientHeight;
              const y =
                element.getBoundingClientRect().top +
                window.pageYOffset +
                yOffset;
              window.scrollTo({ top: y, behavior: "smooth" });
            }
          });
        });
      }
    };

    return {
      isMenuVisible,
      toggleMenu,
      scrollToComponent,
      goToAboutPage,
      goToHomePage,
    };
  },
});
</script>

<style lang="scss">
.header {
  display: flex;
  width: 100%;
  padding: 1.375rem 5.438rem;
  height: 6.375rem;
  justify-content: space-between;
  align-items: center;
  background-color: #05051f;
  box-sizing: border-box;
  position: fixed;
  z-index: 2;
  font-family: var(--font-primary);
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0.938rem;
    font-family: var(--font-primary);
  }

  @media (max-width: 1200px) {
    padding: 0.625rem;
    height: auto;
    font-family: var(--font-primary);
  }
}

.inner-header {
  display: flex;
  width: 95%;
  height: 3.625rem;
  justify-content: space-between;
  margin: 5.438rem 1.375rem;
  align-items: center;
  box-sizing: border-box;
  background-color: #05051f;
  border-radius: 14px;
  font-family: var(--font-primary);
  @media (max-width: 1200px) {
    width: 90%;
    margin: 15px auto;
    font-family: var(--font-primary);
  }

  @media (max-width: 1200px) {
    width: 100%;
    flex-direction: column;
    height: auto;
    margin: 0;
    align-items: center;
    font-family: var(--font-primary);
  }
}

.logo-container {
  display: flex;
  align-items: center;
  gap: 7.866rem;
  position: relative;
  @media (max-width: 1200px) {
    gap: 3.125rem;
  }
}

.logo {
  width: 15.625rem;
  height: 6.25rem;
  cursor: pointer;

  @media (max-width: 1200px) {
    width: 9.375rem;
    height: 3.75rem;
    position: relative;
  }
}

.navigation {
  justify-content: center; // waiting for about

  display: flex;
  width: 42.313rem;
  align-items: center;
  justify-content: space-between; // waiting for about
  gap: 1rem;
  box-sizing: border-box;
  border-radius: 0px 0px 14px 14px;
  display: absolute;
  @media (max-width: 1200px) {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 3.75rem;
    left: 0;
    background-color: #101028;
    align-items: center; // waiting for about
  }
}

.btn,
.other-btn {
  display: flex;
  padding: 0.9375rem 1.25rem;
  align-items: flex-start;
  gap: 0.625rem;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: white;
  margin-left: 1.813rem;
  font-family: var(--font-primary);
  @media (max-width: 1200px) {
    font-size: 0.8rem;
    margin-left: 0;
    font-family: var(--font-primary);
  }
}

@media (max-width: 1400px) {
  .btn,
  .other-btn {
    margin-left: 0.938rem;
  }
}

@media (max-width: 1200px) {
  .btn,
  .other-btn {
    margin-left: 0rem;
    margin: 0.5rem 0; // waiting for about
    padding: 0.75rem 1rem; // waiting for about
  }
}

.btn {
  background-color: #862dff;
  font-family: var(--font-primary);
  background-image: linear-gradient(to right, #862dff, #2a2a72);
  border: 1px solid var(--D_01, #6c60ff);
  background: var(
    --D3,
    linear-gradient(
      224deg,
      rgba(98, 86, 245, 0.8) 42.9%,
      rgba(134, 45, 255, 0.8) 100%
    )
  );

  &:hover {
    background-color: #5722a0;
  }
  padding: 0.9375rem 2.25rem;
}

.modal {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 1.25rem;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 1.75rem;
  font-weight: bold;
  &:hover,
  &:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
}

.other-btn {
  background-color: #101028;
  border: none;
  &:hover {
    background-color: #5722a0;
  }
}

.menu-btn {
  display: none;

  @media (max-width: 1200px) {
    position: absolute;
    top: 50%;
    right: 3.125rem;
    transform: translateY(-50%);
    display: block;
    position: absolute;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 2rem;
  }
}

@media (max-width: 1200px) {
  .header {
    width: 100%;
    padding: 0.9375rem;
  }

  .inner-header {
    width: 90%;
    margin: 0.9375rem auto;
  }

  .navigation {
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: none;
  }

  .logo {
    width: 12.5rem;
    height: 5rem;
  }
}

@media (max-width: 1200px) {
  .header {
    padding: 0.625rem;
  }

  .inner-header {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    margin: 0;
  }

  .logo-container {
    justify-content: space-between;
    width: 100%;
  }

  .logo {
    width: 9.375rem;
    height: 3.75rem;
    position: relative;
    margin-bottom: 0.625rem;
  }

  .menu-btn {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 2rem;
  }

  .navigation {
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 20rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #101028;
  }

  .btn,
  .other-btn {
    margin-left: 0;
  }

  .other-btn {
    width: 95%;
    justify-content: center;
  }
}
</style>
