<template>
  <div class="home" id="home">
    <TopBar />
    <div class="main-content" style="background-color: transparent">
      <router-view />
      <TestHome />
      <FooterBar />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TopBar from "@/components/TopBar.vue";
import FooterBar from "@/components/FooterBar.vue";
import TestHome from "@/views/TestHome.vue";

@Options({
  components: {
    TopBar,
    FooterBar,
    TestHome,
  },
})
export default class HomeView extends Vue {}
</script>
