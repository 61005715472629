<template>
  <div class="expertise-domain" id="expertise">
    <div class="expertise-container">
      <div class="title-box">
        <TitleInfo
          title="Nos domaines d’expertise"
          subtitle="Notre équipe et leur expertise vous fournissent un accompagnement de pointe pour mener des audits approfondis, concevoir des solutions sur mesure, et transformer vos infrastructures pour qu'elles répondent aux exigences les plus récentes de l'industrie et réaliser son intégration et sa maintenance."
        />
      </div>
      <div class="expertise-box">
        <IconExpertise>
          <template v-slot:icon3>
            <img src="assets/icon_expertise/cloud.svg" alt="Cloud" />
          </template>
          <template v-slot:title3>
            <h3 class="title-style">Network Operating Center</h3>
          </template>
          <template v-slot:subtitle3>
            <p class="subtitle-style">
              Centre de services opérationnel 24/7/365 en français et en
              anglais, garantissant la maintenance de vos infrastructures.
            </p>
          </template>

          <template v-slot:icon2>
            <img src="assets/icon_expertise/infra.svg" alt="Infrastructure" />
          </template>
          <template v-slot:title2>
            <h3 class="title-style">Cloud</h3>
          </template>
          <template v-slot:subtitle2>
            <p class="subtitle-style">
              Du Cloud Public au Cloud Privé, en passant par l'Hybridation. Nous
              gérons la création d'environnements, les migrations et le Move to
              Cloud.
            </p>
          </template>
          <template v-slot:icon1>
            <img src="assets/icon_expertise/exploi.svg" alt="NOC" />
          </template>
          <template v-slot:title1>
            <h3 class="title-style">Infrastructure</h3>
          </template>
          <template v-slot:subtitle1>
            <p class="subtitle-style">
              Conseils, design, intégration, optimisation et refonte
              d'infrastructures.
            </p>
          </template>
        </IconExpertise>
        <IconExpertise>
          <template v-slot:icon1>
            <img src="assets/icon_expertise/info.svg" alt="Centrale d’achat" />
          </template>
          <template v-slot:title1>
            <h3 class="title-style">Centrale d’achat</h3>
          </template>
          <template v-slot:subtitle1>
            <p class="subtitle-style">
              Bénéficiez de l'expertise de nos spécialistes pour tous vos
              besoins IT, avec des tarifs avantageux et un crédit chez CCS.
            </p>
          </template>

          <template v-slot:icon2>
            <img src="assets/icon_expertise/secu.svg" alt="SOC" />
          </template>
          <template v-slot:title2>
            <h3 class="title-style">Security Operating Center</h3>
          </template>
          <template v-slot:subtitle2>
            <p class="subtitle-style">
              Centre de services opérationnel 24/7/365 en français et en
              anglais, assurant la sécurisation de vos infrastructures.
            </p>
          </template>
          <template v-slot:icon3>
            <img src="assets/icon_expertise/continu.svg" alt="PCA-PRA" />
          </template>
          <template v-slot:title3>
            <h3 class="title-style">PCA-PRA</h3>
          </template>
          <template v-slot:subtitle3>
            <p class="subtitle-style">
              Conseil et mise en place de Plans de Continuité d'Activité et de
              Plans de Reprise d'Activité pour votre organisation.
            </p>
          </template>
        </IconExpertise>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TitleInfo from "@/components/TitleInfo.vue";
import IconExpertise from "@/components/IconExpertise.vue";
export default {
  name: "ExpertiseDomain",
  components: {
    TitleInfo,
    IconExpertise,
  },
};
</script>

<style scoped lang="scss">
.expertise-domain {
  display: flex;
  padding: 6rem 7.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.5rem;
  align-self: stretch;
  border-radius: 5.6875rem;
  overflow: hidden;
}
.expertise-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3.75rem;
  align-self: stretch;
  border-radius: 1.6875rem;
}

.title-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  align-self: stretch;
}

.expertise-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
}

.title-style {
  color: var(--BLANC, #fff);
  font-family: var(--font-primary);
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.subtitle-style {
  color: var(--BLANC, #fff);
  text-align: center;
  font-family: var(--font-secondary);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 16.125rem;
  opacity: 0.6;
}

@media (max-width: 1200px) {
  .title-box {
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
  .expertise-box {
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .expertise-domain {
    padding: 3rem 3rem;
    gap: 1.5rem;
    border-radius: 1.6875rem;
  }
  .expertise-container {
    gap: 1.5rem;
    border-radius: 1.6875rem;
  }
  .title-box {
    gap: 1.5rem;
    justify-content: left;
    margin-left: -3rem;
  }
  .expertise-box {
    gap: 1.5rem;
  }
}
</style>
```
