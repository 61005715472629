<template>
  <div class="title">
    <h1 class="page-title">{{ title }}</h1>
    <p class="page-subtitle">{{ subtitle }}</p>
  </div>
</template>

<script lang="ts">
export default {
  name: "titleInfo",
  props: {
    title: {
      type: String,
      required: true,
      default: "Titre par défaut",
    },
    subtitle: {
      type: String,
      required: true,
      default: "Sous-titre par défaut",
    },
  },
};
</script>

<style lang="scss">
.title {
  display: flex;
  width: 75rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0rem;
  .page-title {
    text-align: center;
    font-family: var(--font-primary);
    font-size: 3rem;
    font-weight: 700;
    line-height: normal;
    background: linear-gradient(
      270deg,
      #6c60ff,
      #af74ff,
      #cbcaff,
      #6c60ff 100%,
      #af74ff 75%,
      #cbcaff 50%
    );
    background-size: 200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: slide 6s linear infinite;
  }

  @keyframes slide {
    0% {
      background-position: 200% center;
    }
    100% {
      background-position: 0 center;
    }
  }

  .page-subtitle {
    color: var(--BLANC, #fff);
    text-align: center;
    font-family: var(--font-secondary);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

@media (max-width: 1124px) {
  .title {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0rem;
    .page-title {
      font-size: 2.5rem;
      align-self: stretch;
      text-align: center;
    }
    .page-subtitle {
      font-size: 2rem;
      align-self: stretch;
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .title {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    .page-title {
      width: 100%;
      font-size: 2rem;
      align-self: stretch;
      text-align: left;
      margin-left: 0.75rem;
    }
    .page-subtitle {
      width: 100%;
      font-size: 5rem;
      align-self: stretch;
      text-align: left;
      margin-left: 0.75rem;
    }
  }
}
</style>
