<template>
  <div class="home-page-content">
    <canvas id="gradient-canvas" data-transition-in></canvas>
    <div class="content-container">
      <div class="main-content">
        <img src="assets/Logo.svg" class="logo-home" />
        <div class="text-content">
          <h1 class="page-title">
            Transformer les entreprises avec des solutions IT innovantes
          </h1>
          <h2 class="page-subtitle">
            Convergence Connected Services est votre partenaire informatique de
            confiance avec plus de deux décennies d'expérience dans le domaine
          </h2>
        </div>
        <ButtonGroup />
        <LogoCarousel />
        <div id="pourquoi-ccs" class="group-content">
          <div class="container-info">
            <TitleInfo
              title="Pourquoi CCS?"
              subtitle="Choisir CCS, c'est opter pour une collaboration fondée sur l'excellence, l'innovation et un engagement sans faille envers la satisfaction client.
              Découvrez comment notre expertise de pointe et notre approche sur mesure font de nous le partenaire idéal pour concrétiser vos projets informatiques les plus ambitieux."
            />
            <PourquoiCcs />
          </div>
        </div>
        <SomeNumber />
        <ExpertiseDomain />
        <TestimonialPart />
        <FinalPart />
      </div>
    </div>
  </div>
</template>

<script>
import LogoCarousel from "@/components/LogoCarousel.vue";
import ButtonGroup from "@/components/ButtonGroup.vue";
import PourquoiCcs from "@/components/WhyCcs.vue";
import TitleInfo from "@/components/TitleInfo.vue";
import SomeNumber from "@/components/SomeNumber.vue";
import ExpertiseDomain from "@/components/ExpertiseDomain.vue";
import TestimonialPart from "@/components/TestimonialPart.vue";
import FinalPart from "@/components/FinalPart.vue";
import { Gradient } from "whatamesh";

export default {
  name: "TestHome",
  components: {
    LogoCarousel,
    ButtonGroup,
    TitleInfo,
    PourquoiCcs,
    SomeNumber,
    ExpertiseDomain,
    TestimonialPart,
    FinalPart,
  },
  mounted() {
    const gradient = new Gradient();
    gradient.initGradient("#gradient-canvas");
  },
};
</script>

<style lang="scss">
#gradient-canvas {
  width: 100%;
  height: 56rem;
  position: absolute;
  top: 0;
  left: 0;
  --gradient-color-1: #05051d;
  --gradient-color-2: #101026;
  --gradient-color-3: #7c33f5;
  --gradient-color-4: #6056ec;
}

@media screen and (max-width: 1124px) and (min-width: 300px) {
  #gradient-canvas {
    height: 50rem;
    width: 100%;
  }
}

.home-page-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: transparent;
}

.content-container {
  display: flex;
  width: 100%;
  margin-top: -2.313rem;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  padding: 0rem 3.5rem;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  align-self: center;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: transparent;
  position: relative;
}

.main-content {
  display: flex;
  padding: 0 4.125rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  align-self: center;
  border-radius: 0.625rem;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: none;
}

.text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
  align-self: center;
}

.logo-home {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  max-width: 20%;
}

.page-title {
  width: 58.75rem;
  text-align: center;
  font-family: var(--font-primary);
  font-size: 3.375rem;
  font-weight: 700;
  background: var(
    --D_01,
    linear-gradient(90deg, #6c60ff 14.85%, #af74ff 48.53%, #cbcaff 86.66%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-style: normal;
  line-height: normal;
}

.page-subtitle {
  width: 58.75rem;
  color: #fff;
  text-align: center;
  font-family: var(--font-secondary);
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--BLANC, #fff);
  font-style: normal;
  line-height: normal;
}

.container-info {
  display: flex;
  padding: 4rem 0rem;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

@media (max-width: 1124px) {
  .content-container {
    padding: 0 0.625rem;
    margin-top: -6.25rem;
    display: flex;
    flex-direction: row;
  }

  .logo-home {
    width: 80%;
    max-width: 18.75rem;
  }

  .main-content {
    padding: 0 0.625rem;

    .page-title {
      width: 100%;
      font-size: 2rem;
      font-family: var(--font-primary);
    }

    .page-subtitle {
      width: 100%;
      font-size: 1.125rem;
      font-family: var(--font-primary);
    }

    .text-content {
      display: flex;
      //flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.313rem;
      font-family: var(--font-primary);

      .page-title {
        width: 100%;
        font-size: 2rem;
        font-family: var(--font-primary);
      }

      .page-subtitle {
        width: 100%;
        font-size: 1.125rem;
        font-family: var(--font-primary);
      }
    }
  }

  .text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.313rem;

    .page-title {
      width: 100%;
      font-size: 2rem;
      font-family: var(--font-primary);
    }

    .page-subtitle {
      width: 100%;
      font-size: 1.125rem;
      font-family: var(--font-primary);
    }
  }

  .container-info {
    display: flex;
    width: 100%;
    padding: 3rem 0rem;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }
}

@media (min-width: 2800px) {
  .logo-home {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    max-width: 20%;
  }
}
</style>
