import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/legal",
    name: "legal",
    component: () =>
      import(/* webpackChunkName: "legal" */ "../views/LegalView.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import(
        /* webpackChunkName: "privacy-policy" */ "../views/PrivacyView.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (
      to.path === "/privacy-policy" ||
      to.path === "/legal" ||
      to.path === "/about"
    ) {
      return { left: 0, top: 0 };
    } else {
      return savedPosition || { left: 0, top: 0 };
    }
  },
});

export default router;
