import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "testi-content" }
const _hoisted_2 = { class: "content-box" }
const _hoisted_3 = { class: "testi-logo" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "testi-text" }
const _hoisted_6 = { class: "testi-person" }
const _hoisted_7 = { class: "person-photo" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "person-info" }
const _hoisted_10 = { class: "person-name" }
const _hoisted_11 = { class: "person-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["testi-box", { active: $props.isActive }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            src: $props.testimonial.logo,
            alt: "Logo"
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, _toDisplayString($props.testimonial.text), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("img", {
              src: $props.testimonial.photo,
              alt: "Photo",
              class: "person-photo-img"
            }, null, 8, _hoisted_8)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, _toDisplayString($props.testimonial.name), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString($props.testimonial.title), 1)
          ])
        ])
      ])
    ])
  ], 2))
}