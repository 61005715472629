<template>
  <footer class="footer-bar">
    <div class="content-container">
      <div class="content-box">
        <div class="logo-box">
          <img src="assets/FootbarLogo.svg" alt="Logo" />
        </div>
        <div class="infos-box">
          <div class="group-info">
            <h3 class="infos-title">Contact</h3>
            <div class="infos-button">
              Convergence Connected Services<br />
              France <br />
              <a
                href="mailto:contact@convergence-cs.com"
                style="color: #fff; text-decoration: none; cursor: pointer"
              >
                contact@convergence-cs.com</a
              >
              <a
                href="https://www.linkedin.com/company/convergence-connected-services/"
                target="_blank"
                style="text-decoration: none"
              >
                <div class="linkedin-logo">
                  <img src="assets/Linkedin.svg" alt="Linkedin" />``
                </div>
              </a>
            </div>
          </div>
          <div class="group-info">
            <h3 class="infos-title">Sommaire</h3>
            <div class="infos-button">
              <button class="footbar-btn" @click="scrollToComponent('home')">
                Acceuil
              </button>
              <button
                class="footbar-btn"
                @click="scrollToComponent('pourquoi-ccs')"
              >
                Pourquoi CCS ?
              </button>
              <button
                class="footbar-btn"
                @click="scrollToComponent('expertise')"
              >
                Notre offre
              </button>
              <!-- <button class="footbar-btn" @click="goToAboutPage">
                À propos
              </button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="legal-notice">
        <div class="year">
          <p>
            &copy; CCS {{ currentYear }} - Made with love by
            <a
              href="https://www.linkedin.com/in/leyna-lahouri-8872351b8/"
              target="_blank"
              style="
                cursor: pointer;
                color: #fff;
                text-decoration: none;
                font-weight: 400;
              "
              >LL Development</a
            >
          </p>
        </div>
        <div class="mentions-legales">
          <button
            class="footbar-btn"
            @click="goToLegalPage"
            style="
              font-size: 0.7375rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              background: none;
            "
          >
            Mentions légales
          </button>
          <button
            class="footbar-btn"
            @click="goToPrivacyPage"
            style="
              font-size: 0.7375rem;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              background: none;
            "
          >
            Politique de confidentialité
          </button>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "FooterBar",
  setup() {
    const currentYear = new Date().getFullYear();
    const router = useRouter();
    const route = useRoute();

    const goToAboutPage = () => {
      router.push({ name: "about" });
    };

    const goToHomePage = () => {
      router.push({ name: "home" });
    };

    const goToLegalPage = () => {
      router.push({ name: "legal" });
    };

    const goToPrivacyPage = () => {
      router.push({ name: "privacy-policy" });
    };

    const scrollToComponent = (componentId: string) => {
      if (route.name === "home") {
        const element = document.getElementById(componentId);
        const header = document.querySelector(".header");
        if (element && header) {
          const yOffset = -header.clientHeight;
          const y =
            element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      } else {
        router.push({ name: "home" }).then(() => {
          requestAnimationFrame(() => {
            const element = document.getElementById(componentId);
            const header = document.querySelector(".header");
            if (element && header) {
              const yOffset = -header.clientHeight;
              const y =
                element.getBoundingClientRect().top +
                window.pageYOffset +
                yOffset;
              window.scrollTo({ top: y, behavior: "smooth" });
            }
          });
        });
      }
    };

    return {
      scrollToComponent,
      goToAboutPage,
      goToHomePage,
      goToLegalPage,
      goToPrivacyPage,
      currentYear,
    };
  },
});
</script>

<style scoped lang="scss">
.footer-bar {
  display: flex;
  width: 100%;
  padding: 1rem 7.5rem 0rem 7.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  background: linear-gradient(251deg, #101028 12.86%, #211b63 101.63%);
}

.content-container {
  display: flex;
  padding: 1.25rem 0rem;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 2.5rem;
}

.content-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3.25rem 0rem;
  align-items: center;
  align-self: stretch;
  width: 100%;
}

.logo-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.625rem;
  margin-top: -3.5rem;
  border-bottom: none;
}

.linkedin-logo {
  position: relative;
  width: 0.938rem;
  height: 0.938rem;
  border: 0.063rem solid #ffffff;
  background-color: #ffffff;
  color: #ffffff;
  border-radius: 50%;
  padding: 0.313rem;
}

.linkedin-logo img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 65%;
  height: auto;
  transform: translate(-50%, -50%);
  text-decoration: none;
}
.linkedin-logo a {
  text-decoration: none;
}
.linkedin-logo,
.linkedin-logo img {
  border: none;
  box-shadow: none;
}

.infos-box {
  display: flex;
  justify-content: flex-end;
  margin-top: 1.5rem;
}

.infos-title {
  color: var(--BLANC, #fff);
  font-family: var(--font-primary);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex-direction: row;
  justify-content: center;
}

.infos-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3125rem;
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
}

.group-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  margin-right: 5rem;
  margin-top: -1rem;
}

.footbar-btn {
  color: var(--BLANC, #fff);
  font-family: var(--font-secondary);
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: none;
  border: none;
  cursor: pointer;
}

.link-legal {
  color: var(--BLANC, #fff);
  font-family: var(--font-secondary);
  font-size: 0.7375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: none;
  border: none;
  text-align: left;
  text-decoration: none;
  cursor: pointer;
}

.line {
  width: 100%;
  height: 0.0625rem;
  background: #fff;
  margin: 0 auto;
  background: linear-gradient(
    90deg,
    #6c60ff 14.85%,
    #af74ff 48.53%,
    #cbcaff 86.66%
  );
}

.legal-notice {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.mentions-legales {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  margin-top: 0.93rem;
}

.year {
  display: flex;
  width: 36rem;
  align-items: flex-start;
  font-size: 0.8375rem;
  color: #fff;
}

@media (max-width: 1300px) and (min-width: 768px) {
  .footer-bar {
    display: flex;
    width: 100%;
    padding: 1rem 3.75rem 0rem 3.75rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    //gap: 0.625rem;
    background: linear-gradient(251deg, #101028 12.86%, #211b63 101.63%);
  }

  .infos-box {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-top: 1.5rem;
    gap: 1.5rem;
  }

  .content-box {
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    padding: 11.25rem 0rem 1.2rem 0rem;
    align-items: flex-start;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .footer-bar {
    display: flex;
    width: 100%;
    padding: 3rem 1rem 0rem 1rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.625rem;
    border-radius: 0rem;
    background: linear-gradient(251deg, #101028 12.86%, #211b63 101.63%);
  }

  .content-container {
    display: flex;
    padding: 3.25rem 0rem 0rem 0rem;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 1.5rem;
  }

  .content-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    width: 100%;
  }

  .logo-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.625rem;
    margin-top: 1.5rem;
  }

  .logo-box img {
    width: 70%;
  }

  .infos-box {
    display: flex;
    width: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.3125rem;
  }

  .infos-title {
    color: var(--BLANC, #fff);
    font-family: var(--font-primary);
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    flex-direction: row;
    justify-content: center;
  }

  .infos-button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3125rem;
  }

  .group-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
  }

  .footbar-btn {
    color: var(--BLANC, #fff);
    font-family: var(--font-secondary);
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
  }

  .link-legal {
    color: var(--BLANC, #fff);
    font-family: var(--font-secondary);
    font-size: 0.7375rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: none;
    border: none;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
  }

  .line {
    width: 100%;
    height: 0.0625rem;
    background: #fff;
    margin: auto;
    background: linear-gradient(
      90deg,
      #6c60ff 14.85%,
      #af74ff 48.53%,
      #cbcaff 86.66%
    );
  }

  .legal-notice {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    flex-direction: column;
    margin-bottom: 1.25rem;
  }

  .mentions-legales {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-top: 0.5rem;
  }

  .year {
    display: flex;
    width: 36rem;
    align-items: flex-start;
    font-size: 0.7375rem;
  }
}
</style>
