<template>
  <div class="part-content">
    <SaleService />
    <DiscoverUs />
  </div>
</template>

<script lang="ts">
import SaleService from "@/components/SaleService.vue";
import DiscoverUs from "@/components/DiscoverUs.vue";
export default {
  name: "FinalPart",
  components: {
    SaleService,
    DiscoverUs,
  },
};
</script>

<style scoped lang="scss">
.part-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: var(--ANTRA, #05051f);
}

@media (max-width: 818px) {
  .part-content {
    display: flex;
    padding: 3.5rem 1.5rem 5.5rem 1.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    align-self: stretch;
  }
}
</style>
