<template>
  <div class="logo-carousel" ref="logoCarousel">
    <div class="carousel-item" v-for="logo in logos" :key="logo">
      <img :src="logo" alt="Logo de l'entreprise" class="logo-image" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoCarousel",
  data() {
    return {
      logos: [
        "assets/carousel/mca.jpg",
        "assets/carousel/biomedecine.png",
        "assets/carousel/Exodata.png",
        "assets/carousel/asus.png",
        "assets/carousel/Huawei.svg",
        "assets/carousel/Ecritel.png",
        "assets/carousel/wonderbox.png",
        "assets/carousel/ingram.png",
        "assets/carousel/telehouse.png",
        "assets/carousel/arrow.png",
        "assets/carousel/xfusion.jpg",
        "assets/carousel/opinion.png",
        "assets/carousel/celeris.png",
        "assets/carousel/tdsyn.png",
        "assets/carousel/zen.jpeg",
        "assets/carousel/mca.jpg",
        "assets/carousel/biomedecine.png",
      ],
    };
  },
  mounted() {
    this.startCarousel();
  },
  methods: {
    startCarousel() {
      const carousel = this.$refs.logoCarousel;
      setInterval(() => {
        let newScrollPosition = carousel.scrollLeft + 100;
        carousel.scrollTo({ left: newScrollPosition, behavior: "smooth" });

        if (carousel.scrollWidth <= newScrollPosition + carousel.offsetWidth) {
          carousel.scrollTo({ left: 0, behavior: "smooth" });
        }
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.logo-carousel {
  display: flex;
  overflow-x: auto;
  gap: 3.75rem;
  padding: 1.25rem 0;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  justify-content: start;
  align-items: center;
  padding: 2.25rem 0;
  border-radius: 0.625rem;
}

.carousel-item {
  flex: 0 0 auto;
}

.logo-image {
  animation: fadeIn 2s ease-in-out;
  max-width: 80%;
  height: auto;
  width: 15.625rem;
}

.logo-carousel::-webkit-scrollbar {
  display: none;
}

.logo-carousel {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media (max-width: 1124px) {
  .logo-carousel {
    gap: 1.875rem;
    margin-top: 6.25rem;
    margin-bottom: -3.75rem;
  }

  .logo-image {
    width: 12.5rem;
  }
}

@media ((min-width: 800px) and (max-height: 1300px)) {
  .logo-carousel {
    gap: 1.875rem;
    margin-top: 6.25rem;
    margin-bottom: -3.75rem;
  }
}

@media (max-width: 768px) {
  .logo-carousel {
    display: flex;
    overflow-x: auto;
    padding: 1rem 0;
    align-items: center;
    gap: 1rem;
  }

  .carousel-item {
    flex: 0 0 auto;
  }

  .logo-image {
    max-width: 75%;
    height: auto;
  }

  .logo-home {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.442rem;
  }
}

@media (min-width: 1990px) {
  .logo-carousel {
    margin-top: 6.25rem;
    margin-bottom: -3.75rem;
  }
}
</style>
