<template>
  <div class="sales-component-v">
    <div class="mobile-version">
      <div class="sales-component">
        <div class="rect-img">
          <img src="assets/img_tmp/test.png" alt="feature1" />
        </div>
        <div class="content-container">
          <div class="text-container">
            <h1 class="feature-title">
              Délégation de Service Achat Informatique
            </h1>
            <p class="feature-text">
              En tant que partenaire de confiance, nous offrons également des
              services de délégation de service achat informatique. Nous prenons
              en charge la gestion de vos achats informatiques, en veillant à ce
              que vous obteniez les meilleurs produits et services aux prix les
              plus compétitifs du marché.
            </p>
            <p class="feature-text">
              Notre équipe d'experts en achats informatiques s'engage à
              optimiser vos coûts tout en maintenant la qualité et la
              performance des solutions informatiques.
            </p>
          </div>
          <ContactForm buttonText="En savoir plus" buttonClass="btn-info" />
        </div>
        <h1 class="title-mobile" v-if="isMobile">
          Délégation de Service Achat Informatique
        </h1>
        <p class="feature-text-mobile" v-if="isMobile">
          En tant que partenaire de confiance, nous offrons également des
          services de délégation de service achat informatique. Nous prenons en
          charge la gestion de vos achats informatiques, en veillant à ce que
          vous obteniez les meilleurs produits et services aux prix les plus
          compétitifs du marché.
        </p>
        <p class="feature-text-mobile" v-if="isMobile">
          Notre équipe d'experts en achats informatiques s'engage à optimiser
          vos coûts tout en maintenant la qualité et la performance des
          solutions informatiques.
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "SaleService",
  components: {
    ContactForm,
  },
  computed: {
    isMobile() {
      const mobileBreakpoint = 1024;
      return typeof window !== "undefined"
        ? window.innerWidth <= mobileBreakpoint
        : false;
    },
  },
};
</script>

<style scoped lang="scss">
.sales-component {
  display: flex;
  padding: 9rem 15.5rem;
  justify-content: center;
  align-items: center;
  gap: 5.9375rem;
  align-self: stretch;
}

.rect-img img {
  width: 21.9375rem;
  height: 28.5rem;
  border-radius: 0.625rem;
  border: 1px solid var(--D_01, #6c60ff);
  background: lightgray 50% / cover no-repeat;
}

.content-container {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  align-self: center;
  margin-top: -1.025rem;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.feature-title {
  align-self: stretch;
  color: var(--BLANC, #fff);
  font-family: var(--font-primary);
  font-size: 2.8125rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
}

.feature-text {
  align-self: stretch;
  color: var(--BLANC, #fff);
  font-family: var(--font-secondary);
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: left;
}

@media (max-width: 818px) {
  .sales-component-v {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.125rem;
    align-self: stretch;
  }
}

@media (max-width: 768px) {
  .mobile-version {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
  }

  .sales-component {
    flex-direction: column;
    padding: 3rem 1rem;
    align-items: center;
  }

  .rect-img img {
    width: 21.84169rem;
    height: 14.25rem;
    border-radius: 0.625rem;
    border: 1px solid var(--D_01, #6c60ff);
  }

  .title-mobile {
    display: block;
    margin-top: -8rem;
    margin-left: -2.5rem;
    align-self: stretch;
    color: var(--BLANC, #fff);
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    width: 100%;
  }

  .feature-text-mobile {
    display: block;
    margin-top: -5rem;
    margin-left: -2.5rem;
    align-self: stretch;
    color: var(--BLANC, #fff);
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  .text-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  .feature-title {
    font-size: 1.75rem;
  }

  .feature-text {
    font-size: 1rem;
    padding: 0 1rem;
  }

  .btn-info {
    width: 100%;
    padding: 10px 0;
    margin-top: 1rem;
  }
}

@media (min-width: 650px) and (max-width: 980px) and (orientation: landscape) {
  .mobile-version {
    display: none;
  }
}
</style>
