<template>
  <div class="discover-component">
    <div class="discover-box">
      <div class="discover-content">
        <div class="box-content">
          <div class="bubble-logo">
            <svg
              width="126"
              height="51"
              viewBox="0 0 126 51"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M81.8023 41.5759C77.2409 46.9056 70.4646 50.2834 62.8994 50.2834C55.3618 50.2834 48.6074 46.9302 44.0465 41.6342C39.4849 46.9798 32.6983 50.3693 25.1201 50.3693C11.3845 50.3693 0.249634 39.2344 0.249634 25.4988C0.249634 11.7632 11.3845 0.628357 25.1201 0.628357C32.6576 0.628357 39.412 3.9815 43.9729 9.2775C48.5346 3.9319 55.3212 0.54248 62.8994 0.54248C70.5617 0.54248 77.4147 4.00756 81.9769 9.45621C86.5384 4.12651 93.3147 0.748718 100.88 0.748718C114.615 0.748718 125.75 11.8836 125.75 25.6192C125.75 39.3547 114.615 50.4896 100.88 50.4896C93.2175 50.4896 86.3645 47.0245 81.8023 41.5759Z"
                fill="url(#paint0_linear_238_1585)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_238_1585"
                  x1="18.5897"
                  y1="32.5209"
                  x2="109.134"
                  y2="33.6361"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#6C60FF" />
                  <stop offset="0.468939" stop-color="#AF74FF" />
                  <stop offset="1" stop-color="#CBCAFF" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="title">
            Découvrez l'expérience de l'informatique maitrisée
          </div>
          <div class="subtitle">
            Faites confiance aux experts CCS pour vous accompagner dans vos
            <br />
            projets et le maintien en conditions opérationnelles de votre SI
          </div>
        </div>
        <ContactForm
          style="
            display: flex;
            padding: 0.9375rem 1.25rem;
            align-items: center;
            gap: 0.625rem;
            margin-left: 0rem;
            width: 13.125rem;
            justify-content: center;
          "
        ></ContactForm>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "DiscoverUs",
  components: {
    ContactForm,
  },
};
</script>

<style scoped lang="scss">
.discover-component {
  display: flex;
  padding: 0rem 7.5rem 6.25rem 7.5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  align-self: stretch;
  //z-index: 0;
}

.discover-box {
  display: flex;
  padding: 5rem 0rem;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  align-self: stretch;
  border-radius: 0.625rem;
  background: var(--D2, linear-gradient(155deg, #101028 33.6%, #211b63 93.25%));
  justify-content: center;
  //z-index: 0;
}

.discover-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  border-radius: 0.8125rem;
  display: flex;
  margin-left: 0rem;
  width: 13.125rem;
  justify-content: center;
  //z-index: 0;
}

.box-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  //z-index: 0;
}

.bubble-logo {
  width: 7.84381rem;
  height: 3.12169rem;
  fill: var(
    --D_01,
    linear-gradient(90deg, #6c60ff 14.85%, #af74ff 48.53%, #cbcaff 86.66%)
  );
  justify-content: center;
  align-items: center;
}

@media (min-width: 780px) and (max-width: 1324px) {
  .bubble-logo {
    margin-right: 2rem;
  }
}

.title {
  width: 44.375rem;
  color: var(--BLANC, #fff);
  text-align: center;
  font-family: var(--font-primary);
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.subtitle {
  color: var(--BLANC, #fff);
  text-align: center;
  font-family: var(--font-secondary);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btn {
  display: flex;
  padding: 0.9375rem 1.25rem;
  align-items: center;
  gap: 0.625rem;
  margin-left: 0rem;
  width: 13.125rem;
  justify-content: center;
}

@media (min-width: 1440px) {
  .box-content {
    margin-left: 2rem;
  }
}

@media (max-width: 1124px) {
  .discover-component {
    padding: 0rem 3.75rem 3.125rem 3.75rem;
  }

  .discover-box {
    padding: 3rem 0rem;
  }

  .bubble-logo {
    width: 5.5rem;
    height: 2.5rem;
  }

  .title {
    width: 100%;
    font-size: 2.5rem;
  }

  .subtitle {
    font-size: 1.25rem;
  }

  .btn {
    width: 11rem;
  }
}

@media (max-width: 768px) {
  .discover-component {
    display: flex;
    padding: 3rem 1rem 0.25rem 1rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    align-self: stretch;
    width: 100%;
  }

  .discover-box {
    display: flex;
    padding: 3rem 1rem;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    align-self: stretch;
    border-radius: 0.625rem;
    background: var(
      --D2,
      linear-gradient(155deg, #101028 33.6%, #211b63 93.25%)
    );
    width: 100%;
    margin-left: -1.9rem;
  }

  .discover-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    border-radius: 0.8125rem;
  }

  .box-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .bubble-logo {
    width: 7.84381rem;
    height: 3.12169rem;
    fill: var(
      --D_01,
      linear-gradient(90deg, #6c60ff 14.85%, #af74ff 48.53%, #cbcaff 86.66%)
    );
  }

  .title {
    width: 100%;
    color: var(--BLANC, #fff);
    text-align: center;
    font-family: var(--font-primary);
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .subtitle {
    color: var(--BLANC, #fff);
    text-align: center;
    font-family: var(--font-secondary);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .btn {
    display: flex;
    padding: 0.9375rem 1.25rem;
    align-items: center;
    gap: 0.625rem;
    margin-left: 0rem;
    width: 13.125rem;
    justify-content: center;
  }
}
</style>
