<template>
  <div class="testimonial-component">
    <div class="testimonial-box">
      <TestimonialContent
        v-for="(testimonial, index) in testimonials"
        :key="testimonial.id"
        :testimonial="testimonial"
        :is-active="index === activeTestimonial"
        :class="calculatePosition(index)"
      />
    </div>
    <div class="dot-next-testi">
      <div
        v-for="(dot, index) in testimonials"
        :key="'dot-' + index"
        :class="dotClass(index)"
        @click="setActiveTestimonial(index)"
      ></div>
    </div>
  </div>
</template>

<script>
import TestimonialContent from "@/components/TestimonialContent.vue";

export default {
  name: "TestimonialPart",
  components: {
    TestimonialContent,
  },
  data() {
    return {
      activeTestimonial: 1,
      testimonials: [
        {
          id: 1,
          logo: "assets/carousel/wonderbox.png",
          text: '"J\'ai enfin trouvé un fournisseur capable de comprendre et répondre à nos besoins"',
          photo: "assets/edouard.jpg",
          name: "Edouard DUMERIL",
          title: "It Support Manager",
        },
        {
          id: 2,
          logo: "assets/carousel/wonderbox.png",
          text: '"J\'ai enfin trouvé un fournisseur capable de comprendre et répondre à nos besoins"',
          photo: "assets/edouard.jpg",
          name: "Edouard DUMERIL",
          title: "It Support Manager",
        },
        {
          id: 3,
          logo: "assets/xFusion_RGB_PNG-1.png",
          text: '"This is definitely a rare supplier that you could find, who can immediately respond both in English and French !"',
          photo: "assets/头像.jpg",
          name: "Alice LIU",
          title: "Marketing Manager - xFusion Europe Region",
        },
        {
          id: 4,
          logo: "assets/xFusion_RGB_PNG-1.png",
          text: '"This is definitely a rare supplier that you could find, who can immediately respond both in English and French !"',
          photo: "assets/头像.jpg",
          name: "Alice LIU",
          title: "Marketing Manager - xFusion Europe Region",
        },
      ],
    };
  },
  methods: {
    dotClass(index) {
      return {
        "dot-testi-first": index === this.activeTestimonial,
        "dot-testi": index !== this.activeTestimonial,
      };
    },
    setActiveTestimonial(index) {
      this.activeTestimonial = index;
    },
    nextTestimonial() {
      this.activeTestimonial =
        (this.activeTestimonial + 1) % this.testimonials.length;
    },
    previousTestimonial() {
      this.activeTestimonial =
        (this.activeTestimonial - 1 + this.testimonials.length) %
        this.testimonials.length;
    },
    calculatePosition(index) {
      const total = this.testimonials.length;
      const middle = Math.floor(total / 2);
      let positionClass = "";

      let diff = index - this.activeTestimonial;

      if (diff < 0) diff += total;
      if (diff > middle) diff -= total;

      if (diff === 0) {
        positionClass = "active";
      } else if (diff === -1 || diff === total - 1) {
        positionClass = "left";
      } else if (diff === 1 || diff === -total + 1) {
        positionClass = "right";
      } else {
        positionClass = "hidden";
      }

      return positionClass;
    },
    getOpacity(index) {
      return index === this.activeTestimonial ? 1 : 0.5;
    },
  },
  mounted() {
    setInterval(this.nextTestimonial, 7000);
  },
};
</script>

<style lang="scss">
.testimonial-component {
  display: flex;
  width: 90rem;
  padding: 3.25rem 7.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4.5rem;
  background: var(
    --D3,
    linear-gradient(
      224deg,
      rgba(98, 86, 245, 0.8) 42.9%,
      rgba(134, 45, 255, 0.8) 100%
    )
  );
}

.testimonial-box {
  position: relative;
  width: 100%;
  height: 22.3125rem;
  margin-top: 3rem;
}

.dot-next-testi {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: -4rem;
  .dot-testi-first {
    width: 1.375rem;
    height: 0.625rem;
    border-radius: 0.375rem;
    background: #fff;
  }
  .dot-testi {
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 0.375rem;
    background: rgba(148, 148, 148, 0.8);
    mix-blend-mode: multiply;
  }
}

.person-photo-img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .testimonial-component {
    width: 100%;
    padding: 4.25rem 1.25rem 2.5rem 1.25rem;
    gap: 2.5rem;
  }
  .testimonial-box {
    width: 100%;
    height: 22.3125rem;
    margin-top: 3rem;
    .testi-box {
      width: 90%;
      left: 5%;
      transform: translateX(0);
      &.active {
        opacity: 1;
        position: relative;
      }
      &.left,
      &.right,
      &.hidden {
        display: none;
      }
    }
  }
  .dot-next-testi {
    margin-top: -3.5rem;
  }
}

@media (max-width: 575px) {
  .testimonial-component {
    padding: 4.25rem 1.25rem 2.5rem 1.25rem;
    gap: 2.5rem;
  }
  .testimonial-box {
    width: 100%;
    height: 22.3125rem;
    margin-top: 3rem;
  }
  .dot-next-testi {
    margin-top: -1.5rem;
  }
}

@media (max-width: 375px) {
  .testimonial-component {
    padding: 4.25rem 1.25rem 3.5rem 1.25rem;
    gap: 2.5rem;
  }
  .testimonial-box {
    width: 100%;
    height: 22.3125rem;
    margin-top: 3rem;
  }
  .dot-next-testi {
    margin-top: 0.5rem;
  }
}
</style>
```
